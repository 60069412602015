<template>
  <section v-if="collection.length">
    <div class="row pl-3 px-md-5" v-if="!hideTitle">
      <div class="col">
        <h2>Discover This Collection</h2>
      </div>
    </div>
    <div class="row px-3 px-md-5 justify-content-start">
      <div class="col slider-product d-none d-md-block" v-if="collection.length > 2">
        <VueSlickCarousel 
          v-bind="slickSettings"
          class="slider-product-box"
        >
          <router-link
            v-for="(coll, i) in collection" :key="i"
            :to="`/products/collection/${$helpers.format.slug(`${coll.name} ${coll.id}`)}`"
            class="px-1 text-black d-flex flex-column w-100"
          >
            <h5 class="font-14px roboto-medium m-0">
              {{ coll.name }}
            </h5>
            <small v-html="coll.description"></small>
            <small class="roboto-medium mt-1">
              Shop the collection <font-awesome icon="chevron-right" />
            </small>
            <b-img
              fluid-grow
              :src="coll.image"
              :alt="coll.name"
              class="mb-3"
            />
          </router-link>
        </VueSlickCarousel>
      </div>
      <div class="col-md-6" :class="{'d-md-none' : collection.length > 2}" v-for="(coll, i) in collection" :key="i">
        <router-link
          :to="`/products/collection/${$helpers.format.slug(`${coll.name} ${coll.id}`)}`"
          class="text-black d-flex flex-column"
        >
          <h5 class="font-14px roboto-medium m-0">
            {{ coll.name }}
          </h5>
          <small v-html="coll.description"></small>
          <small class="roboto-medium mt-1">
            Shop the collection <font-awesome icon="chevron-right" />
          </small>
          <b-img
            fluid-grow
            :src="coll.image_mobile"
            class="mb-3"
            :alt="coll.name"
          />
        </router-link>
      </div>
    </div>
  </section>
</template>
<script>
import VueSlickCarousel from 'vue-slick-carousel';

export default {
  name: 'MoreCollection',
  components: {
    VueSlickCarousel
  },
  props: ['collection', 'hideTitle'],
  data() {
    return {
      slickSettings: {
        // centerMode: false,
        // centerPadding: "0",
        // slidesToShow: 2,
        slidesToShow: 2,
        slidesToScroll: 2,
        arrows: true,
        swipeToSlide: true,
      },
    }
  }
}
</script>
